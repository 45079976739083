<ng-container *ngrxLet="{ isCollapsed: isCollapsed$, selectedWorkspace: selectedWorkspace$ } as vm">
    <ng-template #menuItemTemplate let-label="label" let-icon="icon" let-iconClass="iconClass" let-link="link">
        <a
            [class.active-route]="(activeRoute$ | ngrxPush) === link"
            [routerLink]="link"
            [matTooltip]="label"
            matTooltipPosition="right"
            [matTooltipDisabled]="!vm.isCollapsed"
            (click)="collapseMenu()"
        >
            <dtm-ui-icon [name]="icon" [ngClass]="iconClass"></dtm-ui-icon>
            <span class="label">{{ label }}</span>
        </a>
    </ng-template>
    <h4
        class="menu-section-header"
        [matTooltip]="'dtmSupClient.menu.missionManagementLabel' | transloco"
        matTooltipPosition="right"
        [matTooltipDisabled]="!vm.isCollapsed"
    >
        {{ "dtmSupClient.menu.missionManagementLabel" | transloco }}
    </h4>
    <ul class="menu-list">
        <li *ngIf="vm.selectedWorkspace | invoke : isPermitted : [WorkspacePermission.PlannedMissions]">
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'dtmSupClient.menu.plannedMissionsLabel' | transloco,
                    icon: 'route',
                    link: '/planned-missions'
                }"
            ></ng-template>
        </li>
        <li *ngIf="vm.selectedWorkspace | invoke : isPermitted : [WorkspacePermission.OperationalSituation]">
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'dtmSupClient.menu.operationalSituationLabel' | transloco,
                    icon: 'treasure-map',
                    link: '/operational-situation'
                }"
            ></ng-template>
        </li>
        <li *ngIf="isMissionSearchFeatureAvailable$ | ngrxPush">
            <ng-template
                [ngTemplateOutlet]="menuItemTemplate"
                [ngTemplateOutletContext]="{
                    label: 'dtmSupClient.menu.missionSearchItemLabel' | transloco,
                    icon: 'search',
                    link: '/mission-search'
                }"
            ></ng-template>
        </li>
    </ul>
    <ng-container *ngIf="vm.selectedWorkspace | invoke : isPermitted : [WorkspacePermission.Monitoring]">
        <h4
            class="menu-section-header"
            [matTooltip]="'dtmSupClient.menu.networkManagementLabel' | transloco"
            matTooltipPosition="right"
            [matTooltipDisabled]="!vm.isCollapsed"
        >
            {{ "dtmSupClient.menu.networkManagementLabel" | transloco }}
        </h4>
        <ul class="menu-list">
            <li>
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'dtmSupClient.menu.networkMonitoringLabel' | transloco,
                        icon: 'base-station',
                        link: '/network-monitoring'
                    }"
                ></ng-template>
            </li>
        </ul>
    </ng-container>
    <ng-container *ngIf="vm.selectedWorkspace | invoke : isPermitted : [WorkspacePermission.PermissionManagement]">
        <h4
            class="menu-section-header"
            [matTooltip]="'dtmSupClient.menu.adminPanelLabel' | transloco"
            matTooltipPosition="right"
            [matTooltipDisabled]="!vm.isCollapsed"
        >
            {{ "dtmSupClient.menu.adminPanelLabel" | transloco }}
        </h4>
        <ul class="menu-list">
            <li>
                <ng-template
                    [ngTemplateOutlet]="menuItemTemplate"
                    [ngTemplateOutletContext]="{
                        label: 'dtmSupClient.menu.addingPermissionsLabel' | transloco,
                        icon: 'user-star',
                        link: '/adding-permissions'
                    }"
                ></ng-template>
            </li>
        </ul>
    </ng-container>
</ng-container>
