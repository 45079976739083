import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DTM_AUTH_GUARD_REQUIRED_ROLES, DtmAuthGuard, NOT_AUTHORIZED_ROUTE, UtmRoleGuard } from "@dtm-frontend/shared/auth";
import { MissionSearchRoutingModule } from "@dtm-frontend/shared/mission";
import { NotAuthorizedComponent, PageNotFoundComponent, RELOAD_COMPONENT_ROUTE_CONFIG } from "@dtm-frontend/shared/ui";
import { NOT_FOUND_ROUTE } from "@dtm-frontend/shared/utils";
import {
    AddingPermissionsRoutingModule,
    NetworkMonitoringRoutingModule,
    OperationalSituationRoutingModule,
    PlannedMissionsRoutingModule,
} from "@dtm-frontend/supervisor-shared-lib";
import { DashboardComponent } from "./components/dashboard/dashboard.component";

const routes: Routes = [
    {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [UtmRoleGuard],
        data: { titleTranslationKey: "dtmSupClient.routeTitles.dashboard" },
    },
    {
        path: "",
        pathMatch: "full",
        redirectTo: "planned-missions",
    },
    {
        path: NOT_AUTHORIZED_ROUTE,
        component: NotAuthorizedComponent,
        canActivate: [DtmAuthGuard],
    },
    {
        path: NOT_FOUND_ROUTE,
        component: PageNotFoundComponent,
        canActivate: [DtmAuthGuard],
    },
    RELOAD_COMPONENT_ROUTE_CONFIG,
    { path: "**", redirectTo: `/${NOT_FOUND_ROUTE}` },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
        PlannedMissionsRoutingModule,
        OperationalSituationRoutingModule,
        NetworkMonitoringRoutingModule,
        AddingPermissionsRoutingModule,
        MissionSearchRoutingModule,
    ],
    exports: [RouterModule],
    providers: [
        {
            provide: DTM_AUTH_GUARD_REQUIRED_ROLES,
            useValue: [],
        },
    ],
})
export class AppRoutingModule {}
