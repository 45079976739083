<dtm-ui-header [routeTitle]="routeTitle$ | ngrxPush" [componentPortalItem]="componentPortalItem$ | ngrxPush">
    <ng-container notificationsSlot>
        <button type="button" class="button-icon notifications-button" [matMenuTriggerFor]="notificationsDropdown">
            <dtm-ui-icon name="notification"></dtm-ui-icon>
        </button>
        <mat-menu #notificationsDropdown="matMenu" xPosition="before">
            <span class="no-notifications-info">
                {{ "dtmSupClient.header.noNotificationsMessage" | transloco }}
            </span>
        </mat-menu>
    </ng-container>
    <supervisor-shared-lib-workspace-switcher
        userButtonSlot
        [userProfile]="userProfile$ | ngrxPush"
        [userName]="userName$ | ngrxPush"
        [selectedWorkspace]="selectedWorkspace$ | ngrxPush"
        (logout)="logout()"
        (workspaceChange)="changeWorkspace($event)"
    ></supervisor-shared-lib-workspace-switcher>
</dtm-ui-header>
