import { ComponentPortal } from "@angular/cdk/portal";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AuthActions, AuthState } from "@dtm-frontend/shared/auth";
import { DeviceSize, DeviceSizeService, RouteDataService } from "@dtm-frontend/shared/ui";
import { SupUserActions, SupUserState } from "@dtm-frontend/supervisor-shared-lib";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { combineLatest } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

@UntilDestroy()
@Component({
    selector: "utm-sup-client-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    protected readonly routeTitle$ = this.routeDataService.routeTitle$;
    protected readonly isLoggedIn$ = this.store.select(AuthState.isLoggedIn);
    protected readonly userName$ = combineLatest([
        this.store.select(AuthState.userFirstName),
        this.store.select(AuthState.userLastName),
    ]).pipe(map(([firstName, lastName]) => `${firstName} ${lastName}`));
    protected readonly componentPortalItem$ = this.routeDataService.routeData$.pipe(
        map((data) => data?.headerComponent),
        distinctUntilChanged(),
        map((headerComponent) => {
            if (!headerComponent) {
                return;
            }

            return new ComponentPortal(headerComponent);
        })
    );
    protected readonly isHeaderUserButtonShortMode$ = this.deviceSizeService.getSizeObservable(
        DeviceSize.Smartphone,
        DeviceSize.SmartphoneWide
    );

    protected readonly userProfile$ = this.store.select(SupUserState.userProfile);
    protected readonly selectedWorkspace$ = this.store.select(SupUserState.selectedWorkspace);

    constructor(
        private readonly store: Store,
        private readonly routeDataService: RouteDataService,
        private readonly deviceSizeService: DeviceSizeService
    ) {}

    protected logout() {
        this.store.dispatch(new AuthActions.Logout());
    }

    protected changeWorkspace(workspaceId: string) {
        this.store.dispatch(new SupUserActions.SetSelectedWorkspace(workspaceId));
    }
}
