import { PortalModule } from "@angular/cdk/portal";
import { DOCUMENT } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthActions, AuthState, KEYCLOAK_CONFIG, SharedAuthModule } from "@dtm-frontend/shared/auth";
import { AZURE_MAPS_SUBSCRIPTION_KEY, SHARED_MAP_ENDPOINTS } from "@dtm-frontend/shared/map";
import { CesiumMapModule } from "@dtm-frontend/shared/map/cesium";
import { FLIGHT_TRACKING_ENDPOINTS, FlightTrackingModule } from "@dtm-frontend/shared/map/flight-tracking";
import { WEATHER_ENDPOINTS, WeatherModule } from "@dtm-frontend/shared/map/geo-weather";
import { GEO_ZONES_ENDPOINTS, GeoZonesModule } from "@dtm-frontend/shared/map/geo-zones";
import { MISSION_SEARCH_ENDPOINTS, MissionSearchModule, UAV_APP_URL } from "@dtm-frontend/shared/mission";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import {
    I18nRootModule,
    LANGUAGE_CONFIGURATION,
    LanguageCode,
    TRANSLATION_ENDPOINTS,
    TranslationHelperService,
    getTranslocoInlineLoader,
} from "@dtm-frontend/shared/ui/i18n";
import { VERSION_DATA_ENDPOINTS, VersionModule } from "@dtm-frontend/shared/ui/version";
import { FunctionUtils, LOCAL_STORAGE, Logger, LoggerModule, RxjsUtils } from "@dtm-frontend/shared/utils";
import { SharedWebsocketModule, SharedWebsocketModuleConfig, WEBSOCKET_CONFIGURATION } from "@dtm-frontend/shared/websocket";
import {
    ADDING_PERMISSIONS_ENDPOINTS,
    AddingPermissionsModule,
    CapabilitiesActions,
    GLOBAL_ALARM_ENDPOINTS,
    GlobalAlarmModule,
    NETWORK_MONITORING_ENDPOINTS,
    NetworkMonitoringModule,
    OPERATIONAL_SITUATION_ENDPOINTS,
    OperationalSituationModule,
    PLANNED_MISSION_ENDPOINTS,
    PlannedMissionsModule,
    SHARED_ENDPOINTS,
    SUP_SOUND_DEFINITIONS,
    SUP_USER_TOKENS,
    SupUserActions,
    SupUserModule,
} from "@dtm-frontend/supervisor-shared-lib";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule, StorageOption } from "@ngxs/storage-plugin";
import { NgxsModule, Store } from "@ngxs/store";
import { KeycloakEventType, KeycloakService } from "keycloak-angular";
import { firstValueFrom, lastValueFrom } from "rxjs";
import { filter, first, tap } from "rxjs/operators";
import { UtmSupervisorClientEnvironment } from "../environments/environment.model";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { HeaderComponent } from "./components/header/header.component";
import { LazyRouterOutletComponent } from "./components/lazy-router-outlet/lazy-router-outlet.component";
import { MenuComponent } from "./components/menu/menu.component";
import { ActionsHandler } from "./services/actions.handler";

Logger.initialize("https://bef3251b7724fdae73da54d075b8a3bc@sentry.pansa.cloud/6");

function getInitialDataFactory(
    keycloakService: KeycloakService,
    store: Store,
    translocoHelper: TranslationHelperService,
    titleService: Title,
    document: Document
) {
    return async () => {
        await firstValueFrom(keycloakService.keycloakEvents$.pipe(filter((event) => event?.type === KeycloakEventType.OnReady)));
        if (!(await keycloakService.isLoggedIn())) {
            store.dispatch(AuthActions.Logout);

            return;
        }

        await firstValueFrom(store.dispatch([new CapabilitiesActions.GetCapabilities(), SupUserActions.FetchUtmUserProfile]));

        // NOTE: transloco will load translations after app initialization, so we can't block app initialization here
        translocoHelper
            .waitForTranslation("dtmSupClient.appTitle")
            .pipe(first(), tap(titleService.setTitle.bind(titleService)))
            .subscribe(() => document.getElementById("loader")?.remove());
    };
}

export function createAppModule(environment: UtmSupervisorClientEnvironment) {
    @NgModule({
        declarations: [AppComponent, DashboardComponent, LazyRouterOutletComponent, HeaderComponent, MenuComponent],
        imports: [
            NgxsModule.forRoot([], {
                // NOTE: must be first because of https://github.com/ngxs/store/issues/375
                developmentMode: !environment.production,
            }),
            AppRoutingModule,
            BrowserAnimationsModule,
            BrowserModule,
            HttpClientModule,
            MatButtonModule,
            MatCardModule,
            MatIconModule,
            MatMenuModule,
            NgxsStoragePluginModule.forRoot({
                key: ["ui"],
                storage: StorageOption.LocalStorage,
            }),
            NgxsReduxDevtoolsPluginModule.forRoot({
                disabled: environment.production,
                name: "UtmSupClient",
            }),
            PortalModule,
            LetModule,
            PushModule,
            LoggerModule.forRoot(environment.name, !environment.production),
            SharedAuthModule.forRoot({
                bearerExcludedUrls: [...environment.bearerExcludedUrls],
            }),
            I18nRootModule.forRoot({
                developmentMode: !environment.production,
                ...LANGUAGE_CONFIGURATION,
            }),
            SharedUiModule.forRoot({ soundDefinitions: SUP_SOUND_DEFINITIONS }),
            VersionModule.forRoot(),
            CesiumMapModule,
            MatTooltipModule,
            PlannedMissionsModule.forRoot(),
            OperationalSituationModule.forRoot(),
            SharedWebsocketModule.forRoot(),
            NetworkMonitoringModule.forRoot(),
            GlobalAlarmModule.forRoot(),
            AddingPermissionsModule.forRoot(),
            GeoZonesModule.forRoot(),
            WeatherModule.forRoot(),
            MissionSearchModule.forRoot(),
            FlightTrackingModule.forRoot(),
            SupUserModule.forRoot(),
        ],
        providers: [
            {
                provide: VERSION_DATA_ENDPOINTS,
                useValue: environment.versionDataEndpoints,
            },
            {
                provide: PLANNED_MISSION_ENDPOINTS,
                useValue: environment.plannedMissionEndpoints,
            },
            {
                provide: SHARED_ENDPOINTS,
                useValue: environment.sharedEndpoints,
            },
            {
                provide: OPERATIONAL_SITUATION_ENDPOINTS,
                useValue: environment.operationalSituationEndpoints,
            },
            {
                provide: NETWORK_MONITORING_ENDPOINTS,
                useValue: environment.networkMonitoringEndpoints,
            },
            {
                provide: GLOBAL_ALARM_ENDPOINTS,
                useValue: environment.globalAlarmEndpoints,
            },
            {
                provide: APP_INITIALIZER,
                useFactory: () => FunctionUtils.noop,
                deps: [ActionsHandler],
                multi: true,
            },
            {
                provide: TRANSLOCO_SCOPE,
                multi: true,
                useValue: {
                    scope: "dtmSupClient",
                    loader: getTranslocoInlineLoader((language: LanguageCode) => import(`../assets/i18n/${language}.json`)),
                },
            },
            {
                provide: SHARED_MAP_ENDPOINTS,
                useValue: environment.sharedMapEndpoints,
            },
            {
                provide: AZURE_MAPS_SUBSCRIPTION_KEY,
                useValue: environment.azureMapsSubscriptionKey,
            },
            {
                provide: MISSION_SEARCH_ENDPOINTS,
                useValue: environment.missionSearchEndpoints,
            },
            {
                provide: UAV_APP_URL,
                useValue: environment.uavAppUrl,
            },
            {
                provide: WEBSOCKET_CONFIGURATION,
                useFactory: (store: Store): SharedWebsocketModuleConfig => ({
                    endpoint: environment.webSocketEndpoint,
                    authorizationTokenProvider: async () =>
                        await lastValueFrom(store.select(AuthState.token).pipe(RxjsUtils.filterFalsy(), first())),
                }),
                deps: [Store],
            },
            {
                provide: KEYCLOAK_CONFIG,
                useValue: environment.keycloakConfig,
            },
            {
                provide: GEO_ZONES_ENDPOINTS,
                useValue: environment.geoZonesEndpoints,
            },
            {
                provide: ADDING_PERMISSIONS_ENDPOINTS,
                useValue: environment.addingPermissionsEndpoints,
            },
            {
                provide: WEATHER_ENDPOINTS,
                useValue: environment.weatherEndpoints,
            },
            {
                provide: TRANSLATION_ENDPOINTS,
                useValue: environment.translationEndpoints,
            },
            { provide: FLIGHT_TRACKING_ENDPOINTS, useValue: environment.flightTrackingEndpoints },
            { provide: SUP_USER_TOKENS, useValue: environment.utmUserEndpoints },
            { provide: LOCAL_STORAGE, useValue: localStorage },
            {
                provide: APP_INITIALIZER,
                useFactory: getInitialDataFactory,
                deps: [KeycloakService, Store, TranslationHelperService, Title, DOCUMENT],
                multi: true,
            },
        ],
        bootstrap: [AppComponent],
    })
    class AppModule {}

    return AppModule;
}
