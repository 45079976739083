import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { WeatherActions } from "@dtm-frontend/shared/map/geo-weather";
import {
    DeviceSize,
    DeviceSizeService,
    RouteDataService,
    SwipeDirection,
    TouchScreenService,
    UIActions,
    UIState,
} from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { DtmName, GlobalAlarmActions, MissionActions, SupUserState, SupWorkspace } from "@dtm-frontend/supervisor-shared-lib";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { filter, map } from "rxjs/operators";

@UntilDestroy()
@Component({
    selector: "utm-sup-client-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    protected readonly isSidebarCollapsed$ = this.store.select(UIState.isMenuCollapsed);
    protected readonly routeTitle$ = this.routeDataService.routeTitle$;
    protected readonly className$ = this.routeDataService.routeData$.pipe(map((routeData) => routeData?.className));

    constructor(
        private readonly store: Store,
        private readonly touchScreenService: TouchScreenService,
        private readonly deviceSizeService: DeviceSizeService,
        private readonly routeDataService: RouteDataService
    ) {}

    public ngOnInit(): void {
        this.observeOnDeviceResize();
        this.observeOnSwipe();

        this.store
            .select(SupUserState.selectedWorkspace)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((workspace) =>
                this.store.dispatch([
                    new MissionActions.SetDtmAreas(this.mapWorkspaceToDtmAreas(workspace)),
                    new GlobalAlarmActions.ZonesAlarmWatch(workspace.authorityUnitZones.map((zone) => zone.designator)),
                ])
            );

        this.store.dispatch(WeatherActions.GetSupportedWeatherZones);
    }

    public toggleSidebar() {
        this.setMenuCollapsedState(!this.store.selectSnapshot(UIState.isMenuCollapsed));
    }

    public collapseMenu(): void {
        if (this.deviceSizeService.isSize(DeviceSize.SmartphoneWide, DeviceSize.Smartphone)) {
            this.setMenuCollapsedState(true);
        }
    }

    private observeOnDeviceResize() {
        this.deviceSizeService
            .getSizeObservable(DeviceSize.Desktop, DeviceSize.DesktopWide)
            .pipe(untilDestroyed(this))
            .subscribe((isDesktopDevice) => {
                this.setMenuCollapsedState(!isDesktopDevice);
            });
    }

    private observeOnSwipe() {
        this.touchScreenService.swipe$
            .pipe(
                filter(() => this.deviceSizeService.isSize(DeviceSize.SmartphoneWide, DeviceSize.Smartphone)),
                untilDestroyed(this)
            )
            .subscribe((direction: SwipeDirection) => {
                if (direction === SwipeDirection.LEFT) {
                    this.setMenuCollapsedState(true);
                } else if (direction === SwipeDirection.RIGHT) {
                    this.setMenuCollapsedState(false);
                }
            });
    }

    private setMenuCollapsedState(isCollapsed: boolean) {
        this.store.dispatch(new UIActions.SetMenuCollapsedState(isCollapsed));
    }

    private mapWorkspaceToDtmAreas(workspace: SupWorkspace | undefined) {
        if (!workspace) {
            return [];
        }

        return workspace.authorityUnitZones.map((unit) => ({
            name: unit.designator as DtmName,
            isAlarm: false,
            dronesNumber: 0,
            bvlosNumber: 0,
            isLeavingArea: false,
            isMissionConflict: false,
            isAdsb: false,
            wind: 0,
            rain: 0,
            temperature: 0,
        }));
    }
}
