import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { WeatherActions } from "@dtm-frontend/shared/map/geo-weather";
import {
    DeviceSize,
    DeviceSizeService,
    RouteDataService,
    SwipeDirection,
    TouchScreenService,
    UIActions,
    UIState,
} from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import {
    DtmAreasActions,
    DtmAreasState,
    DtmStatsErrorType,
    GlobalAlarmActions,
    MissionActions,
    SupUserState,
    SupWorkspace,
} from "@dtm-frontend/supervisor-shared-lib";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { firstValueFrom } from "rxjs";
import { filter, map } from "rxjs/operators";

@UntilDestroy()
@Component({
    selector: "utm-sup-client-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
    protected readonly isSidebarCollapsed$ = this.store.select(UIState.isMenuCollapsed);
    protected readonly routeTitle$ = this.routeDataService.routeTitle$;
    protected readonly className$ = this.routeDataService.routeData$.pipe(map((routeData) => routeData?.className));

    constructor(
        private readonly store: Store,
        private readonly touchScreenService: TouchScreenService,
        private readonly deviceSizeService: DeviceSizeService,
        private readonly routeDataService: RouteDataService,
        private readonly toastr: ToastrService,
        private readonly translationHelperService: TranslationHelperService
    ) {}

    public ngOnInit(): void {
        this.observeOnDeviceResize();
        this.observeOnSwipe();
        this.watchAndHandleDtmStatsErrors();

        this.store
            .select(SupUserState.selectedWorkspace)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((workspace) => {
                const dtmAreas = this.mapWorkspaceToDtmAreas(workspace);
                this.store.dispatch([
                    new MissionActions.SetDefaultMissionFilters(dtmAreas),
                    new DtmAreasActions.SetDtmAreas(dtmAreas),
                    new GlobalAlarmActions.ZonesAlarmWatch(dtmAreas),
                    new DtmAreasActions.GetDtmStats(dtmAreas, workspace?.authorityUnit.id),
                ]);
            });

        this.store.dispatch(WeatherActions.GetSupportedWeatherZones);
    }

    public toggleSidebar() {
        this.setMenuCollapsedState(!this.store.selectSnapshot(UIState.isMenuCollapsed));
    }

    public collapseMenu(): void {
        if (this.deviceSizeService.isSize(DeviceSize.SmartphoneWide, DeviceSize.Smartphone)) {
            this.setMenuCollapsedState(true);
        }
    }

    private observeOnDeviceResize() {
        this.deviceSizeService
            .getSizeObservable(DeviceSize.Desktop, DeviceSize.DesktopWide)
            .pipe(untilDestroyed(this))
            .subscribe((isDesktopDevice) => {
                this.setMenuCollapsedState(!isDesktopDevice);
            });
    }

    private observeOnSwipe() {
        this.touchScreenService.swipe$
            .pipe(
                filter(() => this.deviceSizeService.isSize(DeviceSize.SmartphoneWide, DeviceSize.Smartphone)),
                untilDestroyed(this)
            )
            .subscribe((direction: SwipeDirection) => {
                if (direction === SwipeDirection.LEFT) {
                    this.setMenuCollapsedState(true);
                } else if (direction === SwipeDirection.RIGHT) {
                    this.setMenuCollapsedState(false);
                }
            });
    }

    private setMenuCollapsedState(isCollapsed: boolean) {
        this.store.dispatch(new UIActions.SetMenuCollapsedState(isCollapsed));
    }

    private mapWorkspaceToDtmAreas(workspace: SupWorkspace | undefined) {
        if (!workspace) {
            return [];
        }

        return workspace.authorityUnitZones.map((unit) => unit.designator);
    }

    private watchAndHandleDtmStatsErrors() {
        this.store
            .select(DtmAreasState.getDtmsStatsError)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(async (error) => {
                let message;
                switch (error.type) {
                    case DtmStatsErrorType.CannotGetDtmStats:
                        message = await firstValueFrom(
                            this.translationHelperService.waitForTranslation("dtmSupClient.errors.fetchingDtmStatsErrorMessage")
                        );
                }

                if (message) {
                    this.toastr.error(message);
                }
            });
    }
}
