/* eslint-disable max-len */

import { createDynamicEnvironment } from "@dtm-frontend/shared/utils";
import type DynamicConfiguation from "../assets/dynamic.config.json";
import { UtmSupervisorClientEnvironment } from "./environment.model";

export const dynamicEnvironment = createDynamicEnvironment<UtmSupervisorClientEnvironment, typeof DynamicConfiguation>(
    fetch("../assets/dynamic.config.json").then((config) => config.json()),
    ({ apiDomain, keycloakConfig, sharedMapEndpoints, uavAppUrl }) => {
        const apiUrl = `https://${apiDomain}/api`;
        const webSocketApiUrl = `wss://${apiDomain}/api/ws`;

        return {
            production: false,
            name: "dev",
            bearerExcludedUrls: [],
            webSocketEndpoint: webSocketApiUrl,
            keycloakConfig,
            uavAppUrl,
            versionDataEndpoints: {
                getVersionDetails: `${apiUrl}/system/service-info`,
            },
            plannedMissionEndpoints: {
                getPlannedMission: `${apiUrl}/utm-supervisor/mission-planning/plans`,
                getMissionRoute: `${apiUrl}/utm-supervisor/mission-planning/routes/{{routeId}}`,
                missionAcceptance: `${apiUrl}/utm-supervisor/mission-planning/{{authorityUnitId}}/manual-plan-verification/{{systemVerificationId}}/acceptance`,
                missionRejection: `${apiUrl}/utm-supervisor/mission-planning/{{authorityUnitId}}/manual-plan-verification/{{systemVerificationId}}/rejection`,
                updateMissionPriority: `${apiUrl}/utm-supervisor/mission-planning/{{authorityUnitId}}/manual-plan-verification/{{systemVerificationId}}/flightPriority`,
                dtmMissionsWatch: "/websocket/topic/sup/dtm-name/all",
                wsZoneMissionTopicName: "/websocket/topic/sup/dtm-name/{{zone}}",
                wsMissionWatchTopicName: "/websocket/topic/sup/authority-unit/{{id}}",
                nearbyMissionPlansWatch: "/websocket/topic/sup/nearby",
                getNearbyMissions: `${apiUrl}/utm-supervisor/mission-planning/nearby`,
                updateSupervisorNote: `${apiUrl}/utm-supervisor/mission-planning/{{authorityUnitId}}/manual-plan-verification/{{systemVerificationId}}/note`,
                getFile: `${apiUrl}/utm-supervisor/mission-planning/{{planId}}/attachment/{{fileId}}`,
                missionManagement: `${apiUrl}/utm-supervisor/mission-planning/{{planId}}`,
                getMissionPlanVerification: `${apiUrl}/utm-supervisor/mission-planning/{{planId}}/plan-verification`,
            },
            operationalSituationEndpoints: {
                getDtmAlerts: `${apiUrl}/utm-supervisor/mission-planning/alerts`,
                getMissions: `${apiUrl}/utm-supervisor/mission-planning/missions`,
                getMission: `${apiUrl}/utm-supervisor/mission-planning/mission/{{missionId}}`,
                getCheckins: `${apiUrl}/utm-supervisor/mission-planning/checkins/search`,
                manageTemporaryZones: `${apiUrl}/utm-supervisor/zones/{{authorityUnitId}}/drafts`,
                getTemporaryZones: `${apiUrl}/utm-supervisor/zones/drafts`,
                getZoneDraft: `${apiUrl}/utm-supervisor/zones/drafts/{{zoneDraftId}}`,
                deleteZoneDraft: `${apiUrl}/utm-supervisor/zones/{{authorityUnitId}}/drafts/{{zoneDraftId}}`,
                getElevation: `${apiUrl}/utm-supervisor/zones/elevation`,
                publishZone: `${apiUrl}/utm-supervisor/zones/{{authorityUnitId}}/drafts/publish/{{zoneDraftId}}`,
                getZones: `${apiUrl}/utm-supervisor/zones/search`,
                getZoneDetails: `${apiUrl}/utm-supervisor/zones/{{airspaceElementId}}`,
                getMissionPlanVerification: `${apiUrl}/utm-supervisor/mission-planning/{{planId}}/plan-verification`,
                getMissionDetails: `${apiUrl}/utm-supervisor/mission-planning/missions/{{missionId}}`,
                rejectMission: `${apiUrl}/utm-supervisor/mission-planning/{{authorityUnitId}}/missions/{{missionId}}/rejection`,
                dismissAlert: `${apiUrl}/utm-supervisor/mission-planning/{{authorityUnitId}}/violations/{{missionId}}/review`,
                overrideMissionTime: `${apiUrl}/utm-supervisor/mission-planning/{{authorityUnitId}}/missions/{{missionId}}/override-time`,
                tacticalMissionsWatch: "/websocket/topic/sup/dtm-name",
                missionManagement: `${apiUrl}/utm-supervisor/mission-planning/{{planId}}`,
                finishZone: `${apiUrl}/utm-supervisor/zones/{{authorityUnitId}}/{{airspaceElementId}}/expire`,
                wsFlightControlTopicName: "/websocket/topic/sup/spatial?bbox={{bbox}}",
            },
            networkMonitoringEndpoints: {
                getInstallations: `${apiUrl}/technical-infrastructure/supervisor/installations`,
                getDeviceHistory: `${apiUrl}/technical-infrastructure/supervisor/signal-violations`,
                updateViolationNote: `${apiUrl}/technical-infrastructure/supervisor/installations/{{installationId}}/sectors/{{sectorId}}/violations/{{signalViolationId}}/note`,
            },
            globalAlarmEndpoints: {
                alarmsWatch: "/websocket/topic/sup/dtm-name/all",
                wsZoneAlarmTopicName: "/websocket/topic/sup/dtm-name/{{zoneId}}",
            },
            sharedMapEndpoints,
            azureMapsSubscriptionKey: "HS9JYfhTrM0Ud2smxePEEpkueCl81_SAD2T-VU-ZNFk",
            geoZonesEndpoints: {
                searchAirspaceElements: `${apiUrl}/geospace/airspace-elements/search`,
                wsAirspaceTopicName: "/websocket/topic/geospace/airspace/all",
            },
            weatherEndpoints: {
                getWeatherConditions: `${apiUrl}/weather-forecast/detailed/{{zoneDesignator}}/range`,
                getMissionPlanWeather: `${apiUrl}/weather-forecast/range`,
                getSupportedWeatherZones: `${apiUrl}/weather-forecast/detailed`,
            },
            addingPermissionsEndpoints: {
                getOperatorsList: `${apiUrl}/utm-supervisor/operators`,
                getPilotsList: `${apiUrl}/utm-supervisor/operators/pilots`,
                removeOperatorPermission: `${apiUrl}/utm-supervisor/operators/{{operatorId}}/emergency`,
                setOperatorPermission: `${apiUrl}/utm-supervisor/operators/{{operatorId}}/emergency`,
                removePilotPermission: `${apiUrl}/utm-supervisor/operators/pilots/{{pilotId}}/super-pilot`,
                setPilotPermission: `${apiUrl}/utm-supervisor/operators/pilots/{{pilotId}}/super-pilot`,
                getOperatorPublicProfile: `${apiUrl}/uav-app/public-profile/operators/{{operatorNumber}}`,
                getPilotPublicProfile: `${apiUrl}/uav-app/public-profile/pilots/{{pilotNumber}}`,
            },
            translationEndpoints: {
                getSystemTranslations: `${apiUrl}/system/translations/{{locale}}`,
            },
            flightTrackingEndpoints: {
                enrichFlightPositionUpdates: `${apiUrl}/utm-supervisor/mission-planning/temp-height-enrichment/{{identifier}}`,
            },
            missionSearchEndpoints: {
                getPlans: `${apiUrl}/mission-browsing/plans`,
                getPlanData: `${apiUrl}/mission-browsing/{{planId}}`,
                getPlanVerification: `${apiUrl}/mission-browsing/{{planId}}/plan-verification`,
                getPlanCaaPermitDetails: `${apiUrl}/mission-browsing/{{planId}}/caa-permit-details`,
                getPlanItinerary: `${apiUrl}/mission-browsing/{{planId}}/itinerary`,
                getPublicDetails: `${apiUrl}/mission-browsing/{{planId}}/public-details`,
                getRoute: `${apiUrl}/mission-browsing/routes/{{routeId}}`,
            },
            sharedEndpoints: {
                getCapabilities: `${apiUrl}/utm-supervisor/capabilities`,
            },
            utmUserEndpoints: {
                getUserProfile: `${apiUrl}/utm-supervisor/users/{{userId}}/profile`,
            },
        };
    }
);
